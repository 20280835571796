import React, { Fragment, useEffect, useRef, useState } from "react"
import { Popover, Transition } from "@headlessui/react"
import { MenuIcon, XIcon } from "@heroicons/react/outline"

import logoSvg from "../../../images/RDlogo.svg"
import SocialLinks from "../elementsRD/SocialLinks"
import SocialLinksFooter from "../elementsRD/SocialLinksFooter"

const BRIDGE_LINK = "https://mainnet.odinprotocol.io/validators"
const STAKE_LINK = "https://ping.pub/odin/staking"
const RUNA_LINK = "https://runa.odinprotocol.io/"
const SIGHT_LINK = "https://sight.odinprotocol.io/"
const DOCS_LINK = "https://docs.odinprotocol.io"

const sections = [
  { name: "ecosystem", href: "#ecosystem", current: false },
  { name: "roadmap", href: "#roadmap", current: false },
]

const links = [
  {
    name: "Docs",
    href: "https://docs.odinprotocol.io",
    current: false,
  },
  {
    name: "OdinGPT",
    href: "https://odingpt.io/",
    current: false,
  },
  // {
  //   name: "forum",
  //   href: "https://forum.odinprotocol.io/",
  //   current: false,
  // },
  {
    name: "runa",
    href: RUNA_LINK,
    current: false,
  },
  {
    name: "sight",
    href: SIGHT_LINK,
    current: false,
  },
  {
    name: "stake",
    href: "https://scan.odinprotocol.io/validators",
    current: false,
  },
]

// export default function Header() {
export default function Header({
  isDrawerOpened,
  setIsDrawerOpened,
  drawerRef,
}) {
  const [isScrolled, setIsScrolled] = useState(false)
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [isScrolled])

  return (
    <Popover
      className={`sticky top-0 rounded-xl ${isScrolled ? "shadow-xl" : ""}`}
      style={{
        zIndex: "11",
        backgroundColor: !isScrolled ? "#242424" : "",
        backdropFilter: "contrast(0.6) blur(3px)",
      }}
      data-aos="fade-down"
      data-aos-duration="1500"
    >
      {/* DESKTOP VIEW */}
      <div className={`px-4 sm:px-6 lg:px-6 ${isDrawerOpened && "blur-md"}`}>
        <div className="flex justify-between items-center py-3 md:justify-start">
          {/* Desktop 1st */}
          <div className="flex justify-start lg:w-0 lg:flex-1 items-center gap-3">
            <span className="sr-only">ODIN</span>
            <img
              className="py-2 px-6"
              width={133}
              height={42}
              src={logoSvg}
              style={{ borderRadius: "44px", background: "#fff" }}
              alt="ODIN"
            />
            <div className="hidden lg:flex justify-start lg:w-0 lg:flex-1 gap-3">
              {sections.map(item => (
                <a
                  key={item.name}
                  href={item.href}
                  className="whitespace-nowrap inline-flex items-center justify-center px-3 py-2 opacity-80 text-textPrimary border border-textPrimary text-base font-customExtraBold hover:bg-[yellow] hover:text-black hover:border-[yellow]"
                  style={{
                    borderRadius: "32px",
                    height: "26px",
                    width: "107px",
                  }}
                >
                  {item.name}
                </a>
              ))}
            </div>
          </div>

          {/* Desktop 2nd */}
          <div className="hidden lg:flex justify-center lg:w-0 lg:flex-1">
            <SocialLinks />
          </div>

          {/* Desktop 3rd */}
          <div className="hidden sm:flex items-center sm:justify-end sm:flex-1 lg:w-0 gap-3">
            {links.map(item => (
              <a
                key={item.name}
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                className="whitespace-nowrap inline-flex items-center opacity-80 justify-center px-4 py-2 text-textPrimary border border-textPrimary text-base font-customExtraBold hover:bg-[yellow] hover:text-black hover:border-[yellow]"
                style={{ borderRadius: "44px", height: "26px", width: "84px" }}
              >
                {item.name}
              </a>
            ))}
          </div>

          {/* Hamburguer menu icon */}
          <div className="-mr-2 -my-2 pl-6 lg:hidden">
            <Popover.Button
              onClick={() => setIsDrawerOpened(true)}
              className="rounded-md p-2 inline-flex items-center justify-center text-textPrimary border border-borderPrimary hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            >
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
        </div>
      </div>

      {/* MOBILE VIEW */}
      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          ref={drawerRef}
          focus
          className="absolute z-20 top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden"
        >
          <div className="rounded-lg ring-1 ring-black ring-opacity-5 bg-[#535353ab]">
            <div className="pt-5 pb-6 px-5 flex flex-col justify-between">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="py-2 px-6"
                    width={110}
                    height={42}
                    src={logoSvg}
                    style={{ borderRadius: "44px", background: "#fff" }}
                    alt="ODIN"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button
                    onClick={() => setIsDrawerOpened(false)}
                    className="bg-backgroundSecondary rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
                  >
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6 mb-6">
                <nav className="flex flex-col me-10 items-baseline">
                  {sections.map(tab => (
                    <a
                      key={tab.name}
                      href={tab.href}
                      className="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 my-2 opacity-80 text-textPrimary border border-textPrimary text-base font-customExtraBold hover:bg-[yellow] hover:text-black hover:border-[yellow]"
                      style={{
                        borderRadius: "44px",
                        height: "26px",
                      }}
                    >
                      {tab.name}
                    </a>
                  ))}
                </nav>
              </div>
              <div className="mt-80 flex justify-between">
                <nav className="flex flex-col me-10 items-baseline mb-4">
                  {links.map(tab => (
                    <a
                      key={tab.name}
                      href={tab.href}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="whitespace-nowrap inline-flex items-center justify-center px-5 py-2 my-2 text-textPrimary border border-textPrimary text-base font-customExtraBold hover:bg-[yellow] hover:text-black hover:border-[yellow]"
                      style={{
                        borderRadius: "44px",
                        height: "26px",
                      }}
                    >
                      {tab.name}
                    </a>
                  ))}
                </nav>
                <div className="mt-2 flex flex-col justify-end">
                  <SocialLinksFooter />
                </div>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
